body {
	font-family: "Raleway", sans-serif !important;
	color: #40102a !important;
	/* 
	min-height: calc(100vh - 128px); */
}

:root {
	--toastify-color-progress-light: #40102a !important;
	--toastify-text-color-light: #40102a !important;
	--toastify-font-family: "Raleway", sans-serif !important;
}

.patterned-footer {
	background: url("./assets/img/homebrew_pattern.svg");
	background-position: bottom;
	background-repeat: repeat-x;
	background-size: 30%;
	background-color: #fcfaf7 !important;
	min-height: 15vh;
}

@media (max-width: 992px) {
	.patterned-footer {
		background-size: 50%;
	}
}

@media (max-width: 768px) {
	.patterned-footer {
		background-size: 60%;
	}
}

main {
	margin-top: 100px;
	min-height: calc(85vh - 100px);
}

div#root {
	background: #fcfaf7;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Raleway", sans-serif !important;
	color: #40102a !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}

a,
a:hover {
	color: inherit !important;
	text-decoration: none !important;
}

.btn-primary {
	background: #522b44 !important;
	border-color: #522b44 !important;
	border-radius: 30px !important;
	font-size: 16px !important;
	color: #fff !important;
}
.btn-primary:hover {
	background: #422337 !important;
	border-color: #422337 !important;

	color: #fff !important;
}
.btn-secondary {
	background: #b3bc35 !important;
	border-color: #b3bc35 !important;
	border-radius: 30px !important;
	color: #fff !important;
}

.btn-secondary:hover {
	background: #b3bc35c5 !important;
	border-color: #b3bc35c5 !important;
	color: #fff !important;
}

.text-intro h1 {
	font-size: 21px;
	font-weight: normal;
	min-height: 50px;
}

.feature-text {
	font-size: 18px;
}

.header-logo img {
	max-width: 100px;
}

@media (max-width: 480px) {
	.header-logo img {
		max-width: 80%;
	}
}

.selection-card-small,
.selection-card {
	border-radius: 20px;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
}

.selection-card-small .row {
	width: 100%;
}

.selection-card-small.selected,
.selection-card.selected {
	border: 1px solid #fd5100;
}

.selection-card-small h2 {
	color: #40102a;
	font-size: 16px;
	font-weight: 700;
	/* min-height: 38px; */
}

.selection-card-small-styles h2 {
	min-height: 38px;
}

div.nav-buttons {
	width: 16.666%;
	flex: 0 0 auto;
}

@media (max-width: 1199px) {
	div.nav-buttons {
		width: 25%;
		flex: 0 0 auto;
	}
}

@media (max-width: 450px) {
	div.nav-buttons {
		width: 40%;
		flex: 0 0 auto;
	}
}

.option-group > .row {
	display: flex;
	overflow-x: auto;
	white-space: nowrap;
}

.overflow-y-hidden {
	overflow-y: hidden;
}

.option-group > .row > .text-intro {
	white-space: initial;
}

.option-group > .row > .col-4 {
	display: inline-block;
}

.option-group-no-overflow h3,
.option-group h3 {
	font-size: 16px;
	margin-bottom: 10px;
}

@media (max-width: 1199.98px) {
	.option-group-no-overflow h3,
	.option-group h3 {
		font-size: 16px;
		margin-bottom: 24px;
	}
}

.footer-nav {
	/* background: #522b44; */
	color: white;
	font-size: 21px;
}

.footer-nav--dt {
	color: #522b44;
	font-size: 21px;
}

.navigator {
	display: flex;
	align-items: center;
	font-size: 25px;
	justify-content: flex-end;
}

.vertical-divider {
	width: 1px;
	height: 40px;
	background: #6b3b57;
	margin: 0 10px;
}

.color-circle {
	outline: 2px solid white;
	width: 100%;
	padding-bottom: 100%;
	border-radius: 360px;
}

.color-circle.one {
	background: #fff2cc;
}
.color-circle.two {
	background: #ffd966;
}
.color-circle.three {
	background: #ffc000;
}
.color-circle.four {
	background: #ed7d31;
}
.color-circle.five {
	background: #c55a11;
}
.color-circle.six {
	background: #9a2100;
}
.color-circle.seven {
	background: #843d0d;
}
.color-circle.eight {
	background: #0d0d0d;
}

.edit-icon {
	color: #fff !important;
	padding: 0.4rem;
	background: #eaaa21;
	border-radius: 50%;
	margin: 0.5rem;
	font-size: 0.7rem;
	height: 30px;
	width: 30px;
	display: grid;
	place-items: center;
}

.edit-icon-card {
	position: absolute;
	top: -15px;
	right: -15px;
}

.selected-abv {
	color: #333333;
	font-size: 40px;
	font-weight: bold;
}

.instruction-selection h2,
#recipe-instructions h2 {
	font-size: 21px;
	font-weight: bold;
	color: #40102a;
}

.recipe-option-area h3 {
	font-size: 24px;
	font-weight: bold;
}

.recipe-option h4,
.instruction-selection--method h4 {
	font-weight: 600;
	font-size: 1.125rem;
	color: #40102a;
}

.recipe-option p {
	font-size: 1rem;
	color: #40102a;
}

.checkbox-custom,
.radio-custom {
	opacity: 0;
	position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
	display: inline-block;
	vertical-align: middle;
	margin: 5px;
	cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
	position: relative;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
	content: "";
	background: #fff;
	border: 2px solid #522b44;
	display: inline-block;
	vertical-align: middle;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	font-size: 80px;
	color: #522b44;
}

.radio-custom:checked + .radio-custom-label:before {
	content: "\f00c";
	font-family: "Font Awesome 5 Free";
	color: #522b44;
	font-size: 25px;
}

.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
	outline: 1px solid #ddd; /* focus style */
}

.styled-select__option--is-selected {
	stroke-dashoffset: 0%;
}

.beer-cards-column {
	flex: 0 0 auto;
	width: 150px !important;
}

.nav-items-position {
	position: relative;
	z-index: 99999;
}

@media (max-width: 768px) {
	.card-container-variables {
		min-height: 220px;
	}
}

@media (min-width: 1199px) {
	.card-container-variables {
		height: 400px;
	}
}
.btn-fit.btn {
	padding: 0.375rem 1.75rem;
}

.MuiTypography-body1,
label:not(.MuiRating-root label) {
	font-family: "Raleway", sans-serif !important;
	color: #522b44 !important;
	font-weight: 700 !important;
}

.MuiPopover-root,
.MuiPopover-root .MuiPickersYear-root,
.MuiTypography-body2 {
	font-family: "Raleway", sans-serif !important;
}

.MuiTypography-colorPrimary {
	color: #522b44 !important;
}

.MuiPickersDay-daySelected {
	background-color: #522b44 !important;
}

.MuiPickersDay-daySelected p.MuiTypography-colorInherit {
	color: #fff !important;
}

.footer-nav {
	position: sticky;
	bottom: 0;
	width: 100%;
}

@media (max-width: 1200px) {
	div.col-4__custom-card {
		flex: 0 0 auto;
		width: 25%;
	}
}

@media (max-width: 500px) {
	div.col-4__custom-card {
		flex: 0 0 auto;
		width: 50%;
		margin-bottom: 5rem !important;
	}
}

@media (max-width: 768px) {
	.MuiBox-root {
		width: 90vw !important;
	}
}

p.error {
	color: red !important;
}

.card-fix--no-image {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.cursor-pointer {
	cursor: pointer;
}

.select-no-padding > div {
	padding: 0 !important;
}

a#privacy-link,
a#privacy-link:hover {
	text-decoration: underline !important;
}

.button-reset {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.font-weight-bold {
	font-weight: bold;
}
